<template>
    <div class="menu-tab-list">
        <div
            v-if="isRoutePathPrev"
            class="menu-tab-list__menu-tab-go-route"
        >
            <MenuTabGoRoute :routePath="routePathPrev">
                <IconArrowV2 class="menu-tab-list__icon-go-route"/>
            </MenuTabGoRoute>
        </div>
        <div
            v-if="tabList.length"
            class="menu-tab-list__menu-tab-items"
        >
            <BasePreloaderSkeleton
                v-if="isLoading"
                class="menu-tab-list__menu-tab-items-preloader"
            />
            <MenuTabItem
                v-for="(tab, index) in tabList"
                :key="index"
                :tabId="getTabId(tab)"
                :tabName="getTabName(tab)"
                :tabRouteName="getTabRouteName(tab)"
                :tabRoutePath="getTabRoutePath(tab)"
                :isTabActive="getIsTabActive(tab)"
                :tabMode="tabMode"
                :isTotalCount="getIsTabTotalCount(tab)"
                :totalCount="getTabTotalCount(tab)"
                @onClickTab="onClickTab"
            />
        </div>
        <div class="menu-tab-list__title">
            {{title}}
        </div>
        <div class="menu-tab-list__menu-actions-items">
            <MenuActionItem
                v-for="(action, index) in actionsList"
                :key="index"
                :actionId="getActionId(action)"
                :actionIcon="getActionIcon(action)"
                :actionTooltip="getActionTooltip(action)"
                :actionEmitName="getActionEmitName(action)"
                :isHover="isActionItemHover"
                @onClickAction="onClickAction"
            />
        </div>
    </div>
</template>

<script>
import MenuTabItem from './MenuTabItem.vue';
import MenuActionItem from './MenuActionItem.vue';
import MenuTabGoRoute from './MenuTabGoRoute.vue';
import IconArrowV2 from '../Icons/IconArrowV2.vue';
import BasePreloaderSkeleton from '../Base/BasePreloaderSkeleton.vue';
export default {
    name: 'MenuTabList',
    components: {
        MenuTabItem,
        MenuActionItem,
        MenuTabGoRoute,
        IconArrowV2,
        BasePreloaderSkeleton
    },
    props: {
        tabList: {
            type: Array,
            default: () => ([])
        },
        actionsList: {
            type: Array,
            default: () => ([])
        },
        routePathPrev: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        tabMode: {
            type: String,
            default: 'route',
            validator: (tabMode) => ['route', 'default'].includes(tabMode)
        },
        tabIdActive: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isActionItemHover: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        isRoutePathPrev() {
            return this.routePathPrev !== '';
        }
    },
    methods: {
        getProp(object = {}, propKey = '') {
            return object[propKey] ?? null;
        },
        getTabId(tab = {}) {
            return String(this.getProp(tab, 'id') ?? '');
        },
        getTabName(tab = {}) {
            return String(this.getProp(tab, 'name') ?? '');
        },
        getTabRouteName(tab = {}) {
            return String(this.getProp(tab, 'routeName') ?? '');
        },
        getTabRoutePath(tab = {}) {
            return String(this.getProp(tab, 'routePath') ?? '');
        },
        getIsTabActive(tab = {}) {
            const tabId = this.getTabId(tab);
            return tabId === this.tabIdActive;
        },
        getIsTabTotalCount(tab = {}) {
            return Boolean(this.getProp(tab, 'isTotalCount') ?? false);
        },
        getTabTotalCount(tab = {}) {
            return parseInt(this.getProp(tab, 'totalCount') ?? 0);
        },
        getActionId(action = {}) {
            return String(this.getProp(action, 'id') ?? '');
        },
        getActionIcon(action = {}) {
            return String(this.getProp(action, 'icon') ?? '');
        },
        getActionTooltip(action = {}) {
            return String(this.getProp(action, 'tooltip') ?? '');
        },
        getActionEmitName(action = {}) {
            return String(this.getProp(action, 'emitName') ?? '');
        },
        onClickAction(emitName = '') {
            this.$emit(emitName);
        },
        onClickTab(tabId = '') {
            this.$emit('onClickTab', tabId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .menu-tab-list {
        display: flex;

        &__menu-tab-go-route {
            margin-right: 20px;
        }

        &__icon-go-route {
            width: 15px;
            height: 15px;
            ::v-deep {
                path {
                    fill: #979BA4;
                }
            }
        }

        &__menu-tab-items {
            display: flex;
            // margin-left: 20px;
            background: #F4F5F5;
            border-radius: 10px;
            padding: 5px 10px;
            position: relative;
            overflow: auto;

            &-preloader {
                margin: -5px -10px;
            }

            &::-webkit-scrollbar {
            height: 5px;
            }

            &::-webkit-scrollbar-thumb:horizontal {
                height: 5px;
                display: none;
                &:hover {
                    display: block;
                }
            }
        }

        &__title {
            display: flex;
            align-items: center;
            line-height: 1;
            font-size: 16px;
            font-weight: 500;
            white-space: nowrap;
            margin-left: 10px;
        }

        &__menu-actions-items {
            display: flex;
            margin-left: auto;
        }
    }
</style>
