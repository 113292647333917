<template>
    <div
        :class="['menu-action-item', {isHover}]"
        @click="onClickAction"
    >
        <component
            :is="actionIcon"
            :key="actionId"
        />
        <div class="menu-action-item__label">
            {{actionTooltip}}
        </div>
    </div>
</template>

<script>
import IconDownload from "../Icons/IconDownload.vue";
import IconImport from '../Icons/IconImport.vue';
export default {
    name: 'MenuActionItem',
    components: {
        IconDownload,
        IconImport
    },
    props: {
        actionId: {
            type: String,
            default: ''
        },
        actionIcon: {
            type: String,
            default: ''
        },
        actionTooltip: {
            type: String,
            default: ''
        },
        actionEmitName: {
            type: String,
            default: ''
        },
        isHover: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        onClickAction() {
            this.$emit('onClickAction', this.actionEmitName);
        }
    }
}
</script>

<style lang="scss" scoped>
    .menu-action-item {
        // width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        cursor: pointer;
        transition: all .4s ease-out;
        background: #F4F5F5;
        padding: 7px 8px;

        &.isHover {
            background: #fff;
        }

        &:hover {
            background: #F4F5F5;
        }

        &:not(:last-child) {
            margin-right: 10px;
        }

        &__label {
            margin-left: 8px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
        }
    }
</style>
