<template>
    <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4327 6.03382C12.8269 5.92369 12.2023 5.922 11.5958 6.02889C10.9892 6.13578 10.4165 6.34843 9.90993 6.65111C8.88626 7.26274 8.20604 8.19489 7.97616 9.21897C7.87369 9.67551 7.46834 9.99995 7.00044 9.99995C6.00395 9.99995 5.05672 10.379 4.36505 11.0406C3.67489 11.7008 3.2959 12.5864 3.2959 13.5C3.2959 14.4135 3.67489 15.2991 4.36505 15.9593C5.05672 16.6209 6.00395 17 7.00044 17C7.55273 17 8.00044 17.4477 8.00044 18C8.00044 18.5522 7.55273 19 7.00044 19C5.50149 19 4.05548 18.4308 2.98261 17.4046C1.90822 16.3769 1.2959 14.9733 1.2959 13.5C1.2959 12.0266 1.90822 10.623 2.98261 9.59533C3.87305 8.7436 5.02053 8.20672 6.24198 8.04877C6.72006 6.76247 7.65536 5.66838 8.88413 4.93422C9.60532 4.50332 10.4089 4.20722 11.2487 4.05924C12.0884 3.91126 12.9516 3.91357 13.7904 4.06608C14.6292 4.21859 15.431 4.51905 16.1495 4.95393C16.8682 5.38888 17.4916 5.95123 17.98 6.61327C18.4685 7.27563 18.8116 8.02412 18.9841 8.81696C19.0692 9.20792 19.1117 9.60464 19.1116 10.0013C20.2648 10.0298 21.3647 10.5003 22.1824 11.318C23.0263 12.1619 23.5004 13.3065 23.5004 14.5C23.5004 15.6934 23.0263 16.838 22.1824 17.6819C21.3385 18.5258 20.1939 19 19.0004 19H18.0004C17.4482 19 17.0004 18.5522 17.0004 18C17.0004 17.4477 17.4482 17 18.0004 17H19.0004C19.6635 17 20.2994 16.7366 20.7682 16.2677C21.2371 15.7989 21.5004 15.163 21.5004 14.5C21.5004 13.8369 21.2371 13.201 20.7682 12.7322C20.2994 12.2633 19.6635 12 19.0004 12H18.0004C17.6968 12 17.4095 11.8619 17.2197 11.6248C17.03 11.3877 16.9582 11.0773 17.0247 10.7809C17.1388 10.2729 17.1405 9.75066 17.0298 9.24214C16.9191 8.73335 16.697 8.24315 16.3705 7.80049C16.0437 7.35751 15.6182 6.97015 15.1139 6.66493C14.6095 6.35964 14.0384 6.14395 13.4327 6.03382Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31658 16.0976 8.68342 16.0976 8.29289 15.7071C7.90237 15.3166 7.90237 14.6834 8.29289 14.2929L11.2929 11.2929Z" fill="black"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 11C12.5523 11 13 11.4477 13 12V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V12C11 11.4477 11.4477 11 12 11Z" fill="black"/>
    </svg>
</template>
<script>

export default {
    name: 'IconImport',
    props: {
        width: {
            type: Number,
            default: 18
        },
        height: {
            type: Number,
            default: 18
        },
    },
}
</script>
