import { render, staticRenderFns } from "./MenuTabList.vue?vue&type=template&id=9cf3afdc&scoped=true&"
import script from "./MenuTabList.vue?vue&type=script&lang=js&"
export * from "./MenuTabList.vue?vue&type=script&lang=js&"
import style0 from "./MenuTabList.vue?vue&type=style&index=0&id=9cf3afdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cf3afdc",
  null
  
)

export default component.exports