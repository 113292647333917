<template>
    <router-link
        v-if="isTabModeRoute" 
        :to="tabRoutePath"
        class="tab-menu-item"
    >
        {{tabName}}
    </router-link>
    <div 
        v-else
        :class="{'tab-menu-item_active': isTabActive}"
        class="tab-menu-item"
        @click="onClickTab"
    >
        <div>
            {{tabName}}
        </div>
        <div 
            v-if="isTotalCount"
            class="tab-menu-item__total-count"
        >
            {{totalCount}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'MenuTabItem',
    props: {
        tabId: {
            type: String,
            default: ''
        },
        tabName: {
            type: String,
            default: ''
        },
        tabRouteName: {
            type: String,
            default: ''
        },
        tabRoutePath: {
            type: String,
            default: ''
        },
        tabMode: {
            type: String,
            default: 'route',
            validator: (tabMode) => ['route', 'default'].includes(tabMode)
        },
        isTabActive: {
            type: Boolean,
            default: false
        },
        isTotalCount: {
            type: Boolean,
            default: false
        },
        totalCount: {
            type: Number,
            default: 0
        }
    },
    computed: {
        isTabModeRoute() {
            return this.tabMode === 'route';
        },
        isTabModeDefault() {
            return this.tabMode === 'default';
        }
    },
    methods: {
        onClickTab() {
            this.$emit('onClickTab', this.tabId);
        }
    }
}
</script>

<style lang="scss" scoped>
    .tab-menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 10px;
        border-radius: 4px;
        background: #F4F5F5;
        margin-right: 1px;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #949BA3;
        border-radius: 7px;

        &:not(:last-child) {
			margin-right: 10px;
		}

        &.router-link-active, &_active {
            background: #4A91F1;
            color: #FFFFFF;

            .tab-menu-item__total-count {
                color: #4A91F1;
            }
        }

        &__total-count {
            margin-left: 5px;
            background: #FFFFFF;
            color: #949BA3;
            border-radius: 4px;
            padding: 0px 3px;
        }
    }
</style>