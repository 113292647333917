<template>
    <div 
        class="menu-tab-go-route"
        @click="goToRoute"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'MenuTabGoRoute',
    props: {
        routePath: {
            type: String,
            default: ''
        }
    },
    methods: {
        goToRoute() {
            this.$router.push(this.routePath);
        }
    }
}
</script>

<style lang="scss" scoped>
    .menu-tab-go-route {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F4F5F5;
        border-radius: 10px;
        cursor: pointer;
    }
</style>